// mdc-theme
@use "./sass/theme";

// 3rd party style fixes
@use "./sass/third-party-fixes";

// Normalize.css
@import "./sass/normalize";

// rmwc fixes for mdc-theme
// https://github.com/jamesmfriedman/rmwc/tree/master/src/theme#theme-options
@import "@rmwc/theme/theme.css";



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}
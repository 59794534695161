.container {
  margin-bottom: 24px;
  padding-bottom: 8px;
}
.heading {
  margin: 16px 0 8px 0;
}
.content {
  display: flex;
  flex-flow: row wrap;
  gap: 24px;
}
.booth {
  margin-top: 8px;
}
.description {
  max-width: 400px;
}
.image-container {
  display: flex;
  flex-flow: row nowrap;
}
.image {
  flex: 1 1 360px;
  max-width: 540px;
  min-width: 240px;
}

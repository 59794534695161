.container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.details {
  display: flex;
  flex-flow: column nowrap;
  margin-left: 12px;
}
.avatar {
  width: 80px;
  height: 80px;
}

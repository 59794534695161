.container {
  margin-bottom: 24px;
  padding-bottom: 8px;
}
.list {
  display: flex;
  flex-flow: column wrap;
  gap: 16px;
}
.heading {
  margin: 16px 0 8px 0;
}

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 24px;
}

.content-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-evenly;
  gap: 24px;
  padding: 24px;
}

.logo-container {
  display: flex;
  flex: 1 1 192px;
  max-width: 256px;
}

.links-container {
  display: flex;
  flex-flow: column nowrap;
}

.social-links-container {
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
}

.logo {
  min-width: 192px;
}

.text-link {
  white-space: nowrap;
}

.copyright-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.18);
  padding: 16px;
}

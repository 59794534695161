.container {
  margin-left: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-flow: row nowrap;
}
.timeframe {
  white-space: nowrap;
  flex: 0 0 auto;
}
.description {
  margin-left: 16px;
  display: flex;
  flex-flow: column nowrap;
}

.container {
  max-width: 288px;
}
.content {
  display: flex;
  flex-flow: column nowrap;
  padding: 16px 8px 8px;
  overflow: hidden;
}
.image {
  width: 288px;
  height: 162px;
}
.single-line-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container {
  margin-bottom: 24px;
  padding-bottom: 24px;
}

.join-code {
  display: inline-block;
  margin-left: 8px;
}

.list {
  padding-bottom: 16px;
}
